import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import Page from "../../src/components/Page";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";

import sonnulFavIcon from "../../sonnulFavIcon/favicon.ico";

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="shortcut icon" href={sonnulFavIcon} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/images/SonnulLogoBlue.jpg"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>Sonnul | Web Services & Digtial Marketing Agency</title>
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="description"
          content="Web Services & Digital Marketing Agency in the Greater Sacramento area"
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://assets.maccarianagency.com/screenshots/the-front/social.png"
        />
        <meta
          property="og:title"
          content="Sonnul | Web Services & Digtial Marketing Agency"
        />
        <meta
          property="og:description"
          content="Web Services & Digital Marketing Agency in the Greater Sacramento area"
        />
        <meta property="og:url" content="https://sonnul.com/" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <Page>{props.children}</Page>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
