export const light = {
  alternate: {
    main: "#f7faff",
    dark: "#edf1f7",
  },
  cardShadow: "rgba(23, 70, 161, .11)",
  mode: "light",
  primary: {
    main: '#45d2dc',
    light: '#eafcff',
    dark: '#383a53',
    dark2: '#303249',
    dark3: '#525472',
    lightGray: '#F9F9F9',
    contrastText: '#fff',

  },
  secondary: {
    main: "#e1fdff",
    light: "#ffb74d",
    dark: "#FF9800",
    contrastText: "#45d2dc",
  },
  secondaryButton: {
    main: "#e1fdff",
    contrastText: "#45d2dc",
  },
  whitePrimaryButton:{
    main: '#fff',
    contrastText: '#45d2dc',
  },
  textButton: {
    main: "#636262",
    contrastText: "#636262",
  },
  text: {
    primary: "#1e2022",
    secondary: "#6C6F8F",
    white: "#fff",
    black: "#000",
    gray: "#636262",
    main: "#45d2dc",
    navText: "#244574",
  },
  divider: "rgba(0, 0, 0, 0.12)",
  background: {
    paper: "#ffffff",
    paper2: "#F6F6F6",
    default: "#ffffff",
    level2: "#f5f5f5",
    level1: "#ffffff",
    lightMain: "#F5FFFF",
  },
  whiteIcon: {
    main: '#fff',
  }
};
