exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-development-js": () => import("./../../../src/pages/development.js" /* webpackChunkName: "component---src-pages-development-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-schedule-call-js": () => import("./../../../src/pages/schedule-call.js" /* webpackChunkName: "component---src-pages-schedule-call-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/BlogArticle.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-web-js": () => import("./../../../src/templates/Web.js" /* webpackChunkName: "component---src-templates-web-js" */)
}

